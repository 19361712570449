import React from "react"
import Styles from "./ButtonLink.module.scss"
import LocalizedLink from "../localizedLink";

const ButtonLink = (props) => {
    return (
        <LocalizedLink to={props.link} className={` flex center ${Styles.button}`} >
            <p className={Styles.text} >{props.text}</p>
        </LocalizedLink>
    )
}

export default ButtonLink