import React from "react"
import Styles from "./TopAnnounce.module.scss"
import useTranslations from "../useTranslations";

const TopAnnounce = () => {
    const{ topAnnounce } = useTranslations()
    return (
        <div className={` flex center ${Styles.bar}`} >
            <p className={Styles.text} >{ topAnnounce }</p>
        </div>
    )
}

export default TopAnnounce