import React from "react"
import Styles from "./SectionTitle.module.scss"

const SectionTitle = (props) => {
    return (
        <div className={` flex between align-center ${Styles.container}`} >
            <div className={Styles.line}></div>
            <p className={Styles.text} >{props.titleText}</p>
            <div className={Styles.line}></div>
        </div>
    )
}

export default SectionTitle