import React from "react"
import {graphql, StaticQuery} from 'gatsby'
import Slider from "react-slick"
import Styles from "./ProductSlider.module.scss"
import LocalizedLink from "../localizedLink";

const settings = {
    dots: true,
    infinite: true,
    autoPlay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
};


const ProductSliderSp = () => (

        <StaticQuery
            query={
                graphql`
                query productSliderSpQuery{
                allMdx(
                  filter: {fileAbsolutePath: {regex: "/(ProductSliderSp)/.*\\\\.mdx$/"}}
                  sort: { fields: [fileAbsolutePath], order: ASC }
                ){
                  edges{
                    node{
                      frontmatter{
                        link
                        alt
                        image
                        productName
                       }
                     }
                   }
                 }
                }
              `
            }
            render={(data) => (
                <>

                    <Slider className={`${Styles.slider} products-slider sp`} {...settings}>
                        {data.allMdx.edges.map(({node: img, index}) => (
                            <LocalizedLink to={`/${img.frontmatter.link}/`} key={index}>
                                <img className={Styles.slide} src={img.frontmatter.image} alt={img.frontmatter.alt}/>
                                <div className={'center-text'}>
                                    {img.frontmatter.productName !== "" && (
                                        <p className="product-short-desc">
                                            {img.frontmatter.productName.split("\n").map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>{item}<br /></React.Fragment>
                                                )
                                            })}
                                        </p>
                                    )}
                                </div>
                            </LocalizedLink>
                        ))}
                    </Slider>
                </>
            )}
        />
)

export default ProductSliderSp