import React from "react"
import {graphql, StaticQuery} from 'gatsby'
import Slider from "react-slick"
import Styles from "./TopSlider.module.scss"
import LocalizedLink from "../localizedLink";


const spSettings = {
    slidesToShow: 1,
    centerPadding: 0,
    dots: false,
    arrows: false
};
const TopSliderEnSp = () => (

        <StaticQuery
            query={
                graphql`
                query topSliderEnSpQuery{
                allMdx(
                  filter: {fileAbsolutePath: {regex: "/(TopSliderEnSp)/.*\\\\.mdx$/"}}
                ){
                  edges{
                    node{
                      frontmatter{
                        link
                        alt
                        image
                       }
                     }
                   }
                 }
                }
              `
            }
            render={(data) => (
                <>
                    <Slider className={'sp'} {...spSettings} >
                        {data.allMdx.edges.map(({node: img, index}) => (
                            <LocalizedLink to={`/${img.frontmatter.link}/`} key={index}>
                                <img className={Styles.slide} src={img.frontmatter.image} alt={img.frontmatter.alt}/>
                            </LocalizedLink>
                        ))}
                    </Slider>
                </>
            )}
        />
)

export default TopSliderEnSp