import React from "react"
import {graphql, StaticQuery} from 'gatsby'
import Slider from "react-slick"
import Styles from "./ProductSlider.module.scss"
import LocalizedLink from "../localizedLink";

const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    arrows: true,
};

const ProductSlider = () => (

    <StaticQuery
        query={
            graphql`
                query productSliderPcQuery{
                allMdx(
                  filter: {fileAbsolutePath: {regex: "/(ProductSliderPc)/.*\\\\.mdx$/"}}
                  sort: { fields: [fileAbsolutePath], order: ASC }
                ){
                  edges{
                    node{
                      frontmatter{
                        link
                        alt
                        image
                        productName
                       }
                     }
                   }
                 }
                }
              `
        }
        render={(data) => (
            <>
                <Slider className={`${Styles.slider} products-slider pc`} {...settings}>
                    {data.allMdx.edges.map(({node: img, index}) => (
                        <LocalizedLink to={`/${img.frontmatter.link}/`} key={index}>
                            <img className={Styles.slide} src={img.frontmatter.image} alt={img.frontmatter.alt}/>
                            <div className={'center-text'}>
                                {img.frontmatter.productName !== "" && (
                                    <p className="product-short-desc">
                                        {img.frontmatter.productName.split("\n").map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>{item}<br /></React.Fragment>
                                            )
                                        })}
                                    </p>
                                )}
                            </div>

                        </LocalizedLink>
                    ))}
                </Slider>
            </>
        )}
    />
)

export default ProductSlider