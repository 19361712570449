import React from "react";
import useTranslations from "../useTranslations";
import LocalizedLink from "../localizedLink";
import Styles from "./FooterNav.module.scss";
import { LocaleContext } from "../../components/layout";

const FooterNav = () => {
	const {
		contact,
		recruitment,
		companyInfo,
		RequestaQuote,
		PleaseUseTheInquiryForm,
		PleaseUseIt,
		NewGraduateRecruitmentGuidelines,
		OurBasic,
		SeeCompanyInformation,
	} = useTranslations();

	const { locale } = React.useContext(LocaleContext);
	const FooterJaUrl = () => {
		return (
			<div className="flex row-to-column">
				<a
					href={"https://recruitment.sksato.co.jp/"}
					className={`flex center img-box ${Styles.recruit} ${Styles.footerLink}  `}
					target={"_blank"}
					rel={"noreferrer"}
				>
					<div className={`txt-box ${Styles.txtbox}`}>
						<h3 className={`large`}>{recruitment}</h3>
						<p>{NewGraduateRecruitmentGuidelines}</p>
					</div>
				</a>
				<LocalizedLink
					className={`flex center img-box ${Styles.company} ${Styles.footerLink}  `}
					to={"/company"}
				>
					<div className={`txt-box ${Styles.txtbox}`}>
						<h3 className={`large`}>{companyInfo}</h3>
						<p>
							{OurBasic}
							<br className="sp"/>
							{SeeCompanyInformation}
						</p>
					</div>
				</LocalizedLink>
			</div>
		)
	}
	const FooterEnUrl = () => {
		return (
			<div className="flex row-to-column">
				<LocalizedLink
					className={`flex center img-box ${Styles.recruit} ${Styles.footerLink} `}
					to={"/20210726/"}
				>
					<div className={`txt-box ${Styles.txtbox}`}>
						<h3 className={`large`}>{recruitment}</h3>
						<p>{NewGraduateRecruitmentGuidelines}</p>
					</div>
				</LocalizedLink>

				<LocalizedLink
					className={`flex center img-box ${Styles.company} ${Styles.footerLink}  `}
					to={"/company"}
				>
					<div className={`txt-box ${Styles.txtbox}`}>
						<h3 className={`large`}>{companyInfo}</h3>
						<p>
							{OurBasic}
							<br className="sp"/>
							{SeeCompanyInformation}
						</p>
					</div>
				</LocalizedLink>
			</div>
		)
	}
	return (
		<section className={Styles.footerNav}>
			<LocalizedLink
				className={`flex center img-box ${Styles.contact} ${Styles.footerLink} `}
				to={"/support/"}
			>
				<div className={`txt-box ${Styles.txtbox}`}>
					<h3 className={`large`}>{contact}</h3>
					<p>
						{RequestaQuote}
						<br className="sp"/>
						{PleaseUseTheInquiryForm}
						<br className="sp"/>
						{PleaseUseIt}
					</p>
				</div>
			</LocalizedLink>
			{locale === "ja" ? <FooterJaUrl/> : <FooterEnUrl/>}
		</section>
	);
};

export default FooterNav;
